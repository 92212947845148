import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm$selectedNotificat3;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('LBADialog', {
    attrs: {
      "width": 600,
      "max-width": 700
    },
    on: {
      "close": function ($event) {
        _vm.detailedNotificationDialog = false;
      },
      "click-outside": function ($event) {
        _vm.detailedNotificationDialog = false;
      },
      "keydown-esc": function ($event) {
        _vm.detailedNotificationDialog = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        var _vm$selectedNotificat;
        return [_vm._v(" " + _vm._s((_vm$selectedNotificat = _vm.selectedNotification) === null || _vm$selectedNotificat === void 0 ? void 0 : _vm$selectedNotificat.subject) + " ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        var _vm$selectedNotificat2;
        return [_c('div', {
          domProps: {
            "innerHTML": _vm._s((_vm$selectedNotificat2 = _vm.selectedNotification) === null || _vm$selectedNotificat2 === void 0 ? void 0 : _vm$selectedNotificat2.notificationText)
          }
        })];
      },
      proxy: true
    }, ((_vm$selectedNotificat3 = _vm.selectedNotification) === null || _vm$selectedNotificat3 === void 0 || (_vm$selectedNotificat3 = _vm$selectedNotificat3.link) === null || _vm$selectedNotificat3 === void 0 ? void 0 : _vm$selectedNotificat3.name) != null ? {
      key: "success",
      fn: function () {
        var _vm$selectedNotificat4;
        return [_c('LBAButton', {
          attrs: {
            "to": (_vm$selectedNotificat4 = _vm.selectedNotification) === null || _vm$selectedNotificat4 === void 0 ? void 0 : _vm$selectedNotificat4.link
          }
        }, [_vm._v(" Öffnen ")])];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.detailedNotificationDialog,
      callback: function ($$v) {
        _vm.detailedNotificationDialog = $$v;
      },
      expression: "detailedNotificationDialog"
    }
  }), _c('SiteHeaderComponent', {
    attrs: {
      "header": "Benachrichtigungen"
    }
  }), _c(VCard, {
    staticClass: "mx-4",
    attrs: {
      "flat": ""
    }
  }, [_c(VCardTitle, [_c(VTextField, {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Nachricht suchen ...",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VCardText, [_c(VDataTable, {
    staticClass: "datatable-row-pointer",
    attrs: {
      "search": _vm.search,
      "headers": _vm.headers,
      "items": _vm.notifications,
      "sort-by": "sendDate",
      "sort-desc": true
    },
    on: {
      "click:row": _vm.openNotification
    },
    scopedSlots: _vm._u([{
      key: "item.read",
      fn: function ({
        item
      }) {
        return [_c(VChip, {
          attrs: {
            "small": "",
            "color": item.read ? 'grey lighten-3' : 'green',
            "text-color": item.read ? 'black' : 'white'
          }
        }, [item.read ? _c(VCol, {
          staticClass: "d-flex justify-center"
        }, [_vm._v("Gelesen")]) : _c(VCol, {
          staticClass: "d-flex justify-center"
        }, [_vm._v("Neu")])], 1)];
      }
    }, {
      key: "item.sendDate",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.formatSendDate(item.sendDate)) + " ")];
      }
    }, {
      key: "item.notificationText",
      fn: function ({
        item
      }) {
        return [_c('div', {
          domProps: {
            "innerHTML": _vm._s(item.notificationText)
          }
        })];
      }
    }, {
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [_c(VBtn, {
          staticClass: "elevation-0",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.changeReadStatus(item.nid);
            }
          }
        }, [item.read == false ? _c(VIcon, [_vm._v("mdi-email-check")]) : _vm._e(), item.read == true ? _c(VIcon, [_vm._v("mdi-email-remove")]) : _vm._e()], 1)];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Benachrichtigungen gefunden. ")];
      },
      proxy: true
    }, {
      key: "no-results",
      fn: function () {
        return [_vm._v(" Keine Benachrichtigungen gefunden. ")];
      },
      proxy: true
    }, {
      key: `footer.prepend`,
      fn: function () {
        return [_c(VBtn, {
          staticClass: "ml-2 mb-2",
          attrs: {
            "small": "",
            "depressed": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$vuetify.goTo(_vm.target, _vm.scrollOptions);
            }
          }
        }, [_vm._v(" Nach oben "), _c(VIcon, [_vm._v(" mdi-arrow-up-thin ")])], 1)];
      },
      proxy: true
    }], null, true)
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };